<template>

	<section class="section text-white" style="background-color: #2A4D9B" id="idea">
		<div class="container">
			<header class="section-header">
				<h2>IDEA</h2>
				<hr>
			</header>

			<div class="row gap-y">
				<div class="mx-auto mb-9">
					<img src="@/assets/img/render/4.png" data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">
				</div>

				<div class="w-100"></div>

				<div class="col-lg-4">
					<div class="card card-body border" style="border: .1px solid rgba(255,255,255, 0.2) !important; background-color: transparent !important;">
						<h6 class="card-title">Satellite Data</h6>
						<p class="small-1">SMARTbuoy system will download following yearly, monthly and daily retrospective data from publicly available Sentinel Product Databases.</p>
					</div>
				</div>

				<div class="col-lg-4">
					<div class="card card-body border" style="border: .1px solid rgba(255,255,255, 0.2) !important; background-color: transparent !important;">
						<h6 class="card-title">SMART Sensor Data</h6>
						<p class="small-1">SMART buoy system will have open hardware and software. Smartbuoy programming interface will be no_code based by using Blockly infrastructure. </p>
					</div>
				</div>

				<div class="col-lg-4">
					<div class="card card-body border" style="border: .1px solid rgba(255,255,255, 0.2) !important; background-color: transparent !important;">
						<h6 class="card-title">SMART Detection</h6>
						<p class="small-1">SMART buoy system will validate the data that will be taken from Sentinel Product Databases with in-situ measurements and with Machine Learning algorithms.</p>
					</div>
				</div>

			</div>

		</div>
	</section>

	<section class="section bg-gray">
		<div class="container">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 mr-auto">
					<h4>Satellite Data</h4>
					<p>SMART buoy system will download following yearly, monthly and daily retrospective data from Sentinel Product Databases;</p>
					<div class="row">
						<div class="col-md-8">
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Sea Temperature,</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Dissolved Oxygen,</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Phytoplankton Amount,</p>
						</div>
					</div>
					<p>of the geographic places that Sea Snot incidents happened in history. Data will be validated with SMART buoys measurement and/or with developer’s input.</p>
				</div>

				<div class="col-md-5">
					<video class="aos-init aos-animate" data-aos="fade-up" src="@/assets/img/satellite_1.mp4" poster="@/assets/img/satellite_1.mp4" controls="" autoplay loop muted width="100%"></video>
				</div>
			</div>

			<hr class="my-8">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 ml-auto">
					<h4>SMART Sensor Data</h4>
					<p>SMART buoy system will have open hardware and software. System will perform following in-situ measurements;</p>

					<div class="row">
						<div class="col-md-8">
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> 380-680 nm & 780-980 nm sea medium optical attenuation measurement</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Sea Temperature</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Underwater Sound Speed</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Wind Speed & Direction</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Rain Amount</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Sea State</p>
						</div>
					</div>
					
					<p>Smartbuoy programming interface will be <b>&lt;no_code/&gt;</b> based by using <u>Blockly</u> infrastructure. (drag & drop based)</p>
				</div>

				<div class="col-md-5 order-md-first">
					<video class="aos-init aos-animate" data-aos="fade-up" src="@/assets/img/sensor-data.mp4" poster="@/assets/img/sensor-data.mp4" controls="" autoplay loop muted width="100%"></video>
				</div>
			</div>

			<hr class="my-8">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 mr-auto">
					<h4>SMART Detection</h4>
					<p>SMART buoy system will validate the data that will be taken from Sentinel Product Databases with in-situ measurements and with Machine Learning algorithms will estimate;</p>

					<div class="row">
						<div class="col-md-8">
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Possible Sea Snot occurrence,</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> Yearly temperature change,</p>
						</div>
					</div>
					
					<p>Data will be publicly available from our webpage and people are welcome to contribute to our estimation algorithm:</p>

					<div class="row">
						<div class="col-md-8">
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> by sharing their measurement from their own device, </p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> by constructing a SMART buoy system upon our instruction,</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> by buying SMART buoy  system.</p>
						</div>
					</div>
				</div>

				<div class="col-md-5">
					<img src="@/assets/img/smart-detection.png" data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">
				</div>
			</div>

		</div>
	</section>


</template>

<script>
export default {

}
</script>

<style scoped>

</style>