<template>
	<Header />
	<Cover />
	<Opportunity />
	<Idea />
	<Impact />
	<!-- <WhoElse /> -->
	<Team />
	<Footer />
</template>

<script>
import Header from './components/Header.vue'
import Cover from './components/Cover.vue'
import Opportunity from './components/Opportunity.vue'
import Idea from './components/Idea.vue'
import Impact from './components/Impact.vue'
import WhoElse from './components/WhoElse.vue'
import Team from './components/Team.vue'
import Footer from './components/Footer.vue'

export default {
	name: 'App',
	components: {
		Header,
		Cover,
		Opportunity,
		Idea,
		Impact,
		WhoElse,
		Team,
		Footer
	}
}
</script>

<style>

</style>
