<template>
	<footer class="footer">
		<div class="container">
			<div class="row gap-y align-items-center">

			<div class="col-md-3 text-center text-md-left">
				<a href="#"><img src="@/assets/img/logo-dark.png" alt="logo" style="height: 60px"></a>
			</div>

			<div class="col-md-6"></div>

			<div class="col-md-3 text-center text-md-right">
				<small>© 2022. All rights reserved.</small>
			</div>

			</div>
		</div>
	</footer>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>