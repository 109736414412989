<template>
	<!-- sticky smart static fixed -->
	<nav class="navbar navbar-expand-lg navbar-dark" data-navbar="static">
		<div class="container">

			<div class="navbar-left">
			<button class="navbar-toggler" type="button">☰</button>
			<a class="navbar-brand" href="#">
				<img class="logo-dark" src="@/assets/img/logo-dark.png" alt="logo">
				<img class="logo-light" src="@/assets/img/logo-light.png" alt="logo">
			</a>
			</div>

			<section class="navbar-mobile">
			<nav class="nav nav-navbar ml-auto">
				<a class="nav-link active" href="#">Home</a>
				<a class="nav-link" href="#opportunity">Opportunity</a>
				<a class="nav-link" href="#idea">Idea</a>
				<a class="nav-link" href="#impact">Impact</a>
				<!-- <a class="nav-link" href="#whoelse">Who Else?</a> -->
				<a class="nav-link" href="#team">Team</a>
			</nav>
			</section>

		</div>
	</nav>
</template>

<script>
export default {
	name: 'Header',
}
</script>

<style>

</style>