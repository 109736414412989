<template>
	<header class="header text-white h-fullscreen text-center" :style="{'background-image': 'url('+ require('../assets/img/earth.png') +')'}" data-overlay="8">
		<div class="container">
			<div class="row h-100">
				
				<div class="col-lg-8 mx-auto align-self-center mt-10">
					<h1 class="display-3 fw-500 mb-10">Early Detection of Sea Snot with SMART Buoys System</h1>
					<p class="gap-xy">
						<a class="btn btn-lg btn-round mw-200 btn-primary" href="#opportunity">See More</a>
					</p>
				</div>

				<div class="col-12 align-self-end text-center mt-6">
					<a class="scroll-down-1 scroll-down-white" href="#opportunity"><span></span></a>
				</div>
				
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'Cover'
}
</script>

<style scoped>

</style>