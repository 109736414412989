<template>
	<section class="section bg-gray" id="opportunity">
		<div class="container">
			<header class="section-header">
				<h2>OPPORTUNITY</h2>
				<hr>
			</header>

			<div class="row gap-y align-items-center">
				<div class="col-md-6 mr-auto">
					<h4>Fact</h4>
					<p>In March 2021, the surface waters of Turkey's Sea of Marmara were full of a viscous substance called "sea snot", also known as marine mucilage or sea saliva. That was the most recent large-scale incident of sea snot, but as records indicate, the first incident of sea snot occurred in 1729 in the North Adriatic. Between years 1850 and 2007, the areas with the highest number of sea snot outbreaks were the Adriatic Sea (14) followed by the Tyrrhenian Sea (11), and the Aegean Sea (9). Sea snot is indisputably both an environmental and an economic threat, consequently also affecting public health.</p>
				</div>

				<div class="col-md-5 aos-init aos-animate" data-aos="fade-up">
					<img class="rounded shadow-2" src="@/assets/img/fact.png" alt="...">
					<div style="text-align: center">Mucilage appearance (1850-2022)</div>
					<!-- <small>Danovaro R, Fonda Umani S, Pusceddu A (2009) Climate Change and the Potential Spreading of Marine Mucilage and Microbial Pathogens in the Mediterranean Sea. PLOS ONE 4(9): e7006. https://doi.org/10.1371/journal.pone.0007006</small> -->
				</div>
			</div>

			<hr class="my-8">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 ml-auto">
					<h4>Causes</h4>
					<p>Sea snot is the result of the overgrowth of microscopic algae called phytoplankton, usually because of increased concentrations of phosphorus and nitrogen in the sea. When the phytoplankton run out of nutrients, they begin to die by excreting mucus-like organic matter – sea snot. Increased sea surface temperatures cause the waters to stratify and slow down the currents that would normally help disrupt algae growth and dissolve the sea snot. The main causes of intense, excessive, more frequent and longer lasting sea snot outbreaks can therefore be considered as <b>anthropogenic</b> (i.e. human caused):</p>
					
					<div class="row">
						<div class="col-md-8">
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> untreated wastewater increasing nitrogen and phosphorus concentrations in the sea</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> increased sea/ocean temperatures because of climate changes</p>
						</div>
					</div>
				</div>

				<div class="col-md-5 order-md-first">
					<video class="aos-init aos-animate" data-aos="fade-up" src="@/assets/img/causes.mp4" poster="@/assets/img/causes.mp4" controls="" autoplay loop muted width="100%"></video>
				</div>
			</div>

			<hr class="my-8">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 mr-auto">
					<h4>Impacts</h4>
					<p>Excessive and long-lasting sea snot spreads over an area of several kilometers and thereby, among others:</p>
					<div class="row">
						<div class="col-md-8">
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> severely harms marine life with long term consequences</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> clogs fishing nets and heavily disrupts the fishing industry</p>
							<p><i class="fa fa-caret-right text-lightest mr-2"></i> impacts tourism as a significant source of income of coastal regions</p>
						</div>
					</div>
					<p><u><b>We could mitigate the impact if we knew the area of a possible sea snot outbreak in advance.</b></u></p>
				</div>

				<div class="col-md-5">
					<video class="aos-init aos-animate" data-aos="fade-up" src="@/assets/img/impacts.mp4" poster="@/assets/img/impacts.mp4" controls="" autoplay loop muted width="100%"></video>
				</div>
			</div>

			<hr class="my-8">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 ml-auto">
					<h4>Solution</h4>
					<p>Early detection is important to take action to save marine life. Our solution is to develop a low-cost SMART buoy system that monitors real-time physical parameters of seawater and predicts potential natural disasters. This is done by combining real-time and retrospective data from satellite remote sensing sensors. Early diagnosis of this natural phenomenon will help institutions take early action to start the predetermined plans for preventing potential natural disasters all around the world. </p>
				</div>

				<div class="col-md-5 order-md-first">
					<!-- <video class="aos-init aos-animate" data-aos="fade-up" src="@/assets/img/solution.mp4" poster="@/assets/img/solution.mp4" controls="" autoplay loop muted width="100%"></video> -->
					<img class="rounded shadow-2 aos-ini aos-animation" data-aos="fade-up" src="@/assets/img/solution.png" alt="...">
				</div>
			</div>

		</div>
	</section>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>