<template>
	<section class="section bg-gray" id="team">
		<div class="container">
			<header class="section-header">
				<h2>The SMARTbuoys</h2>
				<hr>
				<p class="lead">Meet our small team that make these great project.</p>
			</header>

			<div class="row gap-y">
			
				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/kaan_alper.jpeg" alt="...">
					</a>
					<h5>Kaan Alper</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/kaan-alper" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>


				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/mutlu_dulger.jpeg" alt="...">
					</a>
					<h5>Mutlu Dülger</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/mutludulger" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>


				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/mert_gursoy.jpeg" alt="...">
					</a>
					<h5>Mert Gürsoy</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/mert-gürsoy-a7042413b" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>


				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/kagan_inan.jpeg" alt="...">
					</a>
					<h5>Kağan İnan</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/kaganinan" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>

			</div>
		</div>
	</section>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>