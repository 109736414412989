<template>
	<section class="section pb-0" id="impact">
		<div class="container">
			<header class="section-header">
				<h2>IMPACT</h2>
				<hr>
			</header>

			<div class="row gap-y text-center">

				<div class="col-6 col-sm-6 col-md-6 col-xl-3 feature-1">
					<div class="card card-body border">
						<p class="feature-icon"><img src="@/assets/img/icons/1.png" style="width: 60px; height: 60px; object-fit: contain"/></p>
						<p class="lead-1">Government institutions</p>
					</div>
				</div>

				<div class="col-6 col-sm-6 col-md-6 col-xl-3 feature-1">
					<div class="card card-body border">
						<p class="feature-icon"><img src="@/assets/img/icons/2.png" style="width: 60px; height: 60px; object-fit: contain"/></p>
						<p class="lead-1">Tourism industry; hotels</p>
					</div>
				</div>

				<div class="col-6 col-sm-6 col-md-6 col-xl-3 feature-1">
					<div class="card card-body border">
						<p class="feature-icon"><img src="@/assets/img/icons/3.png" style="width: 60px; height: 60px; object-fit: contain"/></p>
						<p class="lead-1">Universities; researchers</p>
					</div>
				</div>

				<div class="col-6 col-sm-6 col-md-6 col-xl-3 feature-1">
					<div class="card card-body border">
						<p class="feature-icon"><img src="@/assets/img/icons/4.png" style="width: 60px; height: 60px; object-fit: contain"/></p>
						<p class="lead-1">Developers</p>
					</div>
				</div>

			</div>

		</div>
	</section>

	<section class="section pb-0 pt-0 mt-8 text-white" style="background-color: #2D4191">
		<div class="container">
			<div class="row gap-y align-items-center">
				<div class="col-md-6 p-md-8">
					<h3>User Impact</h3>
					<br>
					<ul class="list-unstyled">
						<li>
							<i class="ti-check mr-2"></i>
							<span>Open Hardware Low-Cost Sensors</span>
						</li>

						<li>
							<i class="ti-check mr-2"></i>
							<span>Open Software</span>
						</li>

						<li>
							<i class="ti-check mr-2"></i>
							<span>Open Access</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

	<!-- <section id="section-mission" class="section">
		<div class="container">
			<div class="row gap-y align-items-center">

				<div class="col-md-4 mx-auto">
					<h2>User Impact</h2>
					<br>

					<p>
					<i class="ti-check text-success mr-2"></i>
					<span> Open Hardware Low-Cost Sensors</span>
					</p>

					<p>
					<i class="ti-check text-success mr-2"></i>
					<span> Open Software</span>
					</p>

					<p>
					<i class="ti-check text-success mr-2"></i>
					<span> Open Access</span>
					</p>
				</div>

				</div>
			</div>
	</section> -->

	<section id="section-value" class="section bg-gray">
		<div class="container">
			<header class="section-header">
				<h3>Value</h3>
				<hr>
			</header>


			<div class="row gap-y align-items-center">
				<div class="col-md-12 mr-auto">
					<p>The following data and the estimations are based on actual losses for Marmara Sea in 2021.</p>
					<table class="table table-striped mt-6">
						<thead>
							<tr>
								<th></th>
								<th>2021 Marmara Case</th>
								<th>with SMARTbuoy</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style="font-weight: 400;">Cleaning</td>
								<td>> 1,0 M $ loss</td>
								<td>% 50 less</td>
							</tr>
							<tr>
								<td style="font-weight: 400;">Tourism</td>
								<td>> 1,0 M $ loss</td>
								<td>% 80 less</td>
							</tr>
							<tr>
								<td style="font-weight: 400;">Shipping</td>
								<td>> 1,0 M $ loss</td>
								<td>% 90 less</td>
							</tr>
							<tr>
								<td style="font-weight: 400;">Fishing Ind.</td>
								<td>> 0,5 M $ loss</td>
								<td>% 70 less</td>
							</tr>
							<tr>
								<td style="font-weight: 400;">Sealife</td>
								<td>Priceless</td>
								<td>% 90 less</td>
							</tr>
						</tbody>
					</table>
					<p><br>The recovery time would have been reduced if we had known the area of possible sea snot outbreak in advance.<br></p>
					<table class="table table-striped mb-6 mt-6">
						<thead>
							<tr>
								<th>Process</th>
								<th>2021 Marmara Case</th>
								<th>with SMARTbuoy</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style="font-weight: 400;">Cleaning</td>
								<td>2 months by manpower, 2 year by nature</td>
								<td>% 50 faster & earlier</td>
							</tr>
							<tr>
								<td style="font-weight: 400;">Sealife</td>
								<td>Approx. 1-2 year</td>
								<td>% 50 faster recovery</td>
							</tr>
						</tbody>
					</table>
					<p>There are currently no existing SMARTbuoy market, but Mooring Buoy Market will hit 1,09 Billion USD by 2028 (Fortune Business Insights)</p>
				</div>
			</div>
		</div>
	</section>

	<section class="section text-white" style="background-color: #2D4191">
		<div class="container">
			<header class="section-header">
				<h4>Social Impact</h4>
				<hr>
				<p class="lead">Help to be ready for <b><u>Action Plans</u></b></p>
			</header>
			<div class="row">
				<div class="col-md-8 mx-auto">
					<div class="video-wrapper">
						<button class="btn btn-circle btn-lg btn-danger"><i class="fa fa-play"></i></button>
						<video src="@/assets/img/social-impact.mp4" controls="" style="width: 100%"></video>
					</div>
				</div>
			</div>
		</div>
	</section>

</template>

<script>
export default {

}
</script>

<style scoped>

</style>